import Axios from '@/axios';

export default {
  sendResetPasswordEmail: async (email) => {
    const response = await Axios.post(`/user-service/v2/reset-password`, {
      email
    });

    return response.data.data;
  },

  listUsers: async (params) => {
    const response = await Axios.get(`/user-service/v2/users/`, {
      params
    });

    return response.data.data;
  },

  deleteUser: async (userId) => {
    const response = await Axios.delete(`/user-service/v2/users/${userId}`);

    return response.data.data;
  },
  updateUser: async (userId, body) => {
    const response = await Axios.patch(`/user-service/v2/users/${userId}`, body);

    return response.data.data;
  },
  createUser: async (body) => {
    const response = await Axios.post(`/user-service/v2/users`, body);

    return response.data.data;
  },
  inviteUser: async (body) => {
    const response = await Axios.post(`/user-service/v2/users/invite`, body);

    return response.data.data;
  },
  retrieveUser: async (userId) => {
    const response = await Axios.get(`/user-service/v2/users/${userId}`);

    return response.data.data;
  },
  sendVerificationEmail: async (userId) => {
    const response = await Axios.post(`/user-service/v2/users/${userId}/verify-email`, {});

    return response.data.data;
  },
  changePassword: async (userId, body) => {
    const response = await Axios.post(`user-service/v2/users/${userId}/change-password`, body);

    return response.data.data;
  },
  completeInvite: async (body) => {
    const response = await Axios.post(`user-service/v2/users/complete-invite`, body);

    return response.data.data;
  },
  completeVerifyEmail: async (body) => {
    const response = await Axios.post(`user-service/v2/users/complete-verify-email`, body);

    return response.data.data;
  },
  completeResetPassword: async (body) => {
    const response = await Axios.post(`user-service/v2/users/complete-reset-password`, body);

    return response.data.data;
  },
  removeOrganization: async (userId, organizationId) => {
    const response = await Axios.delete(`/user-service/v2/users/${userId}/organizations/${organizationId}`);

    return response.data.data;
  }
};

<template>
  <div class="container">
    <b-spinner v-if="!pageLoaded" />
    <b-row v-else class="vh-100" align-v="center">
      <b-col cols="12" sm="12" md="8" offset-md="2" lg="6" offset-lg="3" xl="6" offset-xl="3">
        <b-card class="card-login shadow rounded mx-auto">
          <b-card-body>
            <img class="login-logo" src="@/assets/logo-banner-green.png" />

            <Alert v-if="errorMessage" variant="red">{{ errorMessage }}</Alert>

            <div v-if="success">
              <Alert v-if="success" variant="green" icon="check">Your email was successfully verified.</Alert>
              <BaseButton v-if="success" size="lg" variant="success" @click="pushToLogin">Login</BaseButton>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UserServiceV2 from '@/lib/user-service-v2';
import config from '@/config';
import Alert from '@/components/ui/Alert';
import BaseButton from '@/components/rbComponents/BaseButton.vue';

export default {
  components: {
    Alert,
    BaseButton
  },
  data() {
    return {
      pageLoaded: false,
      errorMessage: '',
      success: false,
      dashboardUrl: `${config.RAFFLEBOX_DASHBOARD_URL}/login`
    };
  },
  metaInfo: {
    title: 'Verify Email'
  },
  async mounted() {
    try {
      const token = this.$route.query.token;

      const completeVerify = {
        token
      };

      await UserServiceV2.completeVerify(completeVerify);

      this.success = true;
    } catch (error) {
      if (error.response) {
        this.errorMessage = error.response.data.errors[0].message;
      } else {
        this.errorMessage = 'Email verification failed. Please contact support.';
      }
    } finally {
      this.pageLoaded = true;
    }
  },
  methods: {
    pushToLogin() {
      this.$router.push(this.dashboardUrl);
    }
  }
};
</script>

<style scoped>
.container {
  margin: auto;
}

.login-logo {
  max-width: 100%;
  max-height: 100%;
  max-width: 80%;
  padding-bottom: 1.5rem;
}

.app-title {
  padding-bottom: 2rem;
}

.credentials-form {
  text-align: left;
}

.card-body {
  text-align: center;
  padding: 1.25rem;
}

.card {
  padding: 1.5rem;
}

.submit-button {
  margin-top: 0.5rem;
}
</style>
